<template>
  <div>
    <!-- Page Header -->
    <base-page-header title="New Pre-Approval Letter" backLink="pre_approval_letters">
      <template #buttons>
        <div class="col-12 d-flex justify-content-md-end text-right">
          <div class="col mt-3 mt-md-0">
            <div class="d-flex justify-content-end">
              <base-button title="Update Preview" action="secondary" @click-btn="preparePreview" />
              <base-button title="Save" action="primary" @click-btn="saveLetter" v-if="showSave" :loading="ui.saving" class="ms-2" />
            </div>
          </div>
        </div>
      </template>
    </base-page-header>

    <!--  Page Breadcrumbs  -->
    <base-breadcrumbs :crumbs="breadcrumbs" />

    <base-page-content class="p-0">
      <template #content>
        <div class="row pt-0 w-100 m-auto">
          <div class="col-sm-6 col-lg-8 border-light-md-right">
            <pre-approval-letter-preview />
          </div>
          <div class="col-sm-6 col-lg-4 p-0">
            <ValidationErrorsList
              class="m-2"
              :error="ui.error"
              :errors="ui.errors"
              :isError="ui.isError"
            />
            <pro-approval-letter-prospect
              @update-borrower="updateBorrower"
              @update-coBorrower="updateCoBorrower"
              @update-source="updateSource"
            />
            <pro-approval-letter
              @update-letter-subject="updateLetterSubject"
              @update-toField="updateToField"
            />
            <pro-approval-letter-m-l-o @update-agent="updateAgent" />
          </div>
        </div>
      </template>
    </base-page-content>
  </div>
</template>

<script>
import BasePageHeader from "../../components/BasePageHeader";
import BaseBreadcrumbs from "../../components/BaseBreadcrumbs";
import BasePageContent from "../../components/BasePageContent";
import PreApprovalLetterPreview from "./includes/PreApprovalLetterPreview";
import ProApprovalLetterProspect from "./includes/PreApprovalLetterProspect";
import ProApprovalLetter from "./includes/PreApprovalLetter";
import ProApprovalLetterMLO from "./includes/ProApprovalLetterMLO";
import ValidationErrorsList from "@/components/form/ValidationErrorsList";
import BaseButton from '../../components/BaseButton.vue';

export default {
  name: "PreApprovalLetter",
  components: {
    ProApprovalLetterMLO,
    ProApprovalLetter,
    ProApprovalLetterProspect,
    PreApprovalLetterPreview,
    BasePageContent,
    BaseBreadcrumbs,
    BasePageHeader,
    ValidationErrorsList,
    BaseButton
  },
  data() {
    return {
      breadcrumbs: [
        { title: "Resource Center", link: "resource_center" },
        { title: "Pre-Approved Letters", link: "pre_approval_letters" },
        { title: "New Pre-Approval", link: "pre_approval_letter_create" }
      ],
      program: "",
      downPayment: "0",
      purchasePrice: "0",
      loanAmount: "0",
      source: "custom",
      letterSubject: "",
      letterTo: "",
      propertyAddress: "",
      borrower: {
        firstName: "",
        lastName: ""
      },
      coBorrower: {
        firstName: "",
        lastName: ""
      },
      selectedAgent: {},
      ui: {
        saving: false,
        isError: false,
        error: "",
        errors: []
      },
      previewData: {},
    }
  },
  methods: {
    preparePreview() {
      this.previewData = {
        letterSubject: this.letterSubject,
        letterTo: this.letterTo,
        borrowerFirstName: this.borrower.firstName,
        borrowerLastName: this.borrower.lastName,
        coBorrowerFirstName: this.coBorrower.firstName,
        coBorrowerLastName: this.coBorrower.lastName,
        propertyAddress: this.propertyAddress,
        program: this.program,
        downPayment: this.downPayment,
        purchasePrice: this.purchasePrice,
        loanAmount: this.loanAmount,
        mloPhoneNumber: this.selectedAgent.phone,
        mloSignatureText: this.selectedAgent.signature,
        mloFirstName: this.selectedAgent.firstName,
        mloLastName: this.selectedAgent.lastName,
        mloPosition: this.selectedAgent.position,
        mloNmls: this.selectedAgent.nmls,
        mloEmail: this.selectedAgent.email
      };

      this.$root.$emit("show-letter-preview", this.previewData);
    },
    updateBorrower(borrower) {
      console.log(borrower)
      if (borrower !== null) {
        this.borrower.firstName = borrower.firstName ? borrower.firstName : "";
        this.borrower.lastName = borrower.lastName ? borrower.lastName : "";
        this.loanAmount = borrower.amount ? borrower.amount : "0";
        this.program = borrower.program ? borrower.program : "";
        this.downPayment = borrower.downPayment ? borrower.downPayment : "0";
        this.purchasePrice = borrower.purchasePrice ? borrower.purchasePrice : "0";
        this.propertyAddress = borrower.address ? borrower.address : "";
      } else {
        this.borrower.firstName = "";
        this.borrower.lastName = "";
        this.loanAmount = "0";
        this.propertyAddress = "";
      }
    },
    updateCoBorrower(coBorrower) {
      if (coBorrower !== null) {
        this.coBorrower.firstName = coBorrower.firstName
          ? coBorrower.firstName
          : "";
        this.coBorrower.lastName = coBorrower.lastName
          ? coBorrower.lastName
          : "";
      } else {
        this.coBorrower.firstName = "";
        this.coBorrower.lastName = "";
      }
    },
    updateSource(source) {
      this.source = source;
    },
    updateLoanAmount(amount) {
      this.loanAmount = amount;
    },
    updateAddress(address) {
      this.propertyAddress = address;
    },
    updateLetterSubject(subject) {
      this.letterSubject = subject;
    },
    updateToField(toField) {
      this.letterTo = toField;
    },
    updateAgent(agent) {
      this.selectedAgent = agent;
      this.preparePreview();
    },
    saveLetter() {
      this.ui.saving = true;

      let formData = new FormData();
      formData.append("source", this.source);
      formData.append("loanAmount", this.loanAmount);
      formData.append("purchasePrice", this.purchasePrice);
      formData.append("downPayment", this.downPayment);
      formData.append("program", this.program);
      formData.append("letterSubject", this.letterSubject);
      formData.append("letterTo", this.letterTo);
      formData.append("propertyAddress", this.propertyAddress);
      formData.append("borrowerFirstName", this.borrower.firstName);
      formData.append("borrowerLastName", this.borrower.lastName);
      formData.append("coBorrowerFirstName", this.coBorrower.firstName);
      formData.append("coBorrowerLastName", this.coBorrower.lastName);
      formData.append("mloId", this.selectedAgent.id);
      formData.append("mloFirstName", this.selectedAgent.firstName);
      formData.append("mloLastName", this.selectedAgent.lastName);
      formData.append("mloPosition", this.selectedAgent.position);
      formData.append("mloNmls", this.selectedAgent.nmls);
      formData.append("mloPhoneNumber", this.selectedAgent.phone);
      formData.append("mloEmail", this.selectedAgent.email);
      formData.append("mloSignatureText", this.selectedAgent.signature);

      this.ui.isError = false;
      this.ui.error = "";
      this.ui.errors = [];

      this.$http
        .post("/api/v1/pre-approved-letters/create", formData)
        .then(res => {
          this.alertSuccess(res.data.message);
          this.$router.push("/resource-center/pre-approval-letters");
        })
        .catch(err => {
          if (err.response.data) {
            let validation = this.getErrorsFromResponse(err.response);
            this.ui.isError = true;
            this.ui.error = validation.error;
            this.ui.errors = validation.errors;
            this.alertError(err.response.data.message);
          } else {
            this.alertError(err.response.statusText);
          }
        })
        .finally(() => (this.ui.saving = false));
    }
  },
  computed: {
    showSave() {
      return (
        this.borrower.firstName &&
        this.selectedAgent !== null
      );
    }
  },
  beforeMount() {
    this.preparePreview();
  }
};
</script>

<style lang="scss" scoped>
.page-header {
  padding-top: 10px;
}
.text-right {
  text-align: right !important;
}
</style>
